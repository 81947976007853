import React, { CSSProperties } from "react";

import {
  formatProp,
  Responsive,
  ResponsiveProps,
  responsiveStyling,
} from "@styles/responsive";
import { styled } from "@styles/theme";

type AditionalStyles = {
  responsiveStyles?: {
    [k in keyof CSSProperties]: Responsive<CSSProperties[k]>;
  };
};
interface Props extends ResponsiveProps, AditionalStyles {
  children: React.ReactNode;
  alignItems?: Responsive<CSSProperties["alignItems"]>;
  justify?: Responsive<CSSProperties["justifyContent"]>;
}

const Container = styled.div<Props>`
  display: grid;

  ${(p) => {
    return responsiveStyling({
      gridTemplate: p.gridTemplate,
      gridTemplateColumns: p.templateCols,
      alignItems: formatProp<CSSProperties["alignItems"]>(
        p.alignItems,
        "stretch",
      ),
      justify: formatProp<CSSProperties["justifyContent"]>(
        p.justify,
        "flex-start",
      ),
      ...p.responsiveStyles,
    });
  }}
`;

export default function Grid({ children, ...props }: Props) {
  return <Container {...props}>{children}</Container>;
}
